import styled from "styled-components";
import { breakpoints } from "../constants";
import { IconConstruction } from "../icons/IconConstruction";
import { IconDatabase } from "../icons/IconDatabase";
import { IconEthernet } from "../icons/IconEthernet";
import { IconGlobe } from "../icons/IconGlobe";
import { IconMedicalService } from "../icons/IconMedicalService";
import { IconSell } from "../icons/IconSell";
import { colors } from "../stylingVariables";

const StyledContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  @media (min-width: ${breakpoints.TABLET}px) {
    padding: 24px;
  }

  svg {
    transform: scale(0.8);
    fill: ${colors.cyan};
  }
`;

const StyledDescription = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  line-height: 150%;
`;

export const CompanyService = ({ data }) => {
  const handleIconType = () => {
    switch (data.type) {
      case "repair":
        return <IconConstruction />;
      case "data-recovery":
        return <IconMedicalService />;
      case "network":
        return <IconEthernet />;
      case "data-security":
        return <IconDatabase />;
      case "web":
        return <IconGlobe />;
      case "sell":
        return <IconSell />;
      default:
        return "";
    }
  };

  return (
    <StyledContainer>
      {handleIconType()}
      <StyledDescription>{data.description}</StyledDescription>
    </StyledContainer>
  );
};
