import styled from "styled-components";
import { AppContext } from "../App";
import { useContext } from "react";
import { breakpoints } from "../constants";

const StyledSectionHeadline = styled.h2`
  margin-bottom: 56px;
  margin-top: 0;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (min-width: ${breakpoints.TABLET}px) {
    font-size: 32px;
  }

  @media (min-width: ${breakpoints.DESKTOP}px) {
    font-size: 40px;
  }
`;

export const SectionHeadline = ({ index }) => {
  const { state } = useContext(AppContext);
  return <StyledSectionHeadline>{state.navigation.navLinks[index]}</StyledSectionHeadline>;
};
