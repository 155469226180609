import styled from "styled-components";
import {colors} from "../stylingVariables";
import {createPortal} from "react-dom";
import {useContext} from "react";
import {AppContext} from "../App";
import {Actions} from "../AppReducer";

const StyledMobileNavigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-center: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.blackTransparent};
  position: fixed;
`

const StyledListItem = styled.div`
  color: ${colors.white};
  font-size: 32px;
  text-align: center;
  margin: 16px 0;
`

export const MobileNavigation = ({scrollToSection}) => {
  const {state, dispatch} = useContext(AppContext);

  const handleOnClick = (index) => {
    scrollToSection(index);
    dispatch({type: Actions.SHOW_MOBILE_NAVIGATION, payload: false});
  }

  return createPortal(
    <StyledMobileNavigation>
      {state.navigation.navLinks.map((listItem, index) => {
        return (
          <StyledListItem key={index} onClick={() => handleOnClick(index)}>
            {listItem}
          </StyledListItem>
        );
      })}
    </StyledMobileNavigation>,
    document.body
  );
}