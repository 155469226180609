import styled from "styled-components";
import { sectionLayout } from "../stylingVariables";
import { ContactForm } from "./ContactForm";
import { useContext } from "react";
import { AppContext } from "../App";
import { SectionHeadline } from "./SectionHeadline";

const StyledContact = styled.section`
  ${sectionLayout};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Contact = () => {
  const { refs } = useContext(AppContext);
  return (
    <>
      <StyledContact ref={refs.contactSection}>
        <SectionHeadline index={4} />
        <ContactForm />
      </StyledContact>
    </>
  );
};
