import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { breakpoints } from "../constants";
import { sectionLayout } from "../stylingVariables";
import { SectionHeadline } from "./SectionHeadline";

const StyledReferences = styled.section`
  ${sectionLayout};
  height: auto;
`;

const StyledReferenceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;
  align-items: center;
  justify-items: center;

  @media (min-width: ${breakpoints.TABLET}px) {
    grid-template-columns: 1fr 1fr;
  };

  @media (min-width: ${breakpoints.DESKTOP}px) {
    grid-template-columns: 1fr 1fr 1fr;
  };
`;

const StyledReference = styled.img`
  max-width: 100%;
`;

export const References = () => {
  const { refs, state } = useContext(AppContext);
  return (
    <StyledReferences ref={refs.referencesSection}>
      <SectionHeadline index={2} />
      <StyledReferenceWrapper>
        {state.references.map((reference, index) => {
          return <StyledReference src={reference.imageUrl} key={index} alt={reference.altText} />;
        })}
      </StyledReferenceWrapper>
    </StyledReferences>
  );
};
