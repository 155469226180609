import { useEffect, useState } from "react"

export const useWindowSize = () => {
    const [currentWidth, setCurrentWidth] = useState(window.outerWidth);
    const [currentHeight, setCurrentHeight] = useState(window.outerHeight);

    const handleResize = () => {
        setCurrentWidth(window.outerWidth)
        setCurrentHeight(window.outerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.addEventListener('resize', handleResize);  
        }
    }, []);

    return {
        currentWidth, currentHeight
    }

}