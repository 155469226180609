import styled from "styled-components";
import { colors } from "../stylingVariables";

const StyledTextArea = styled.textarea`
  height: 80px;
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid ${colors.black};
  border-radius: 2px;
  resize: none;
  ${({ hasError }) =>
    hasError &&
    `
    border-color: ${colors.red};
		margin-bottom: 0;
  `};
`;

const StyledErrorMessage = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 24px 8px;
  color: ${colors.red};
`;

export const FormFieldTextArea = ({
  name,
  placeholder,
  hasError,
  errorFeedback,
  onChange,
  onBlur,
  value
}) => {
  return (
    <>
      <StyledTextArea
        name={name}
        placeholder={placeholder}
        hasError={hasError}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {hasError && <StyledErrorMessage>{errorFeedback}</StyledErrorMessage>}
    </>
  );
};
