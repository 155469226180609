import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { breakpoints } from "../constants";
import { useWindowSize } from "../hooks/useWindowSize";
import { colors } from "../stylingVariables";
import { IconMenu } from "../icons/IconMenu";
import { Actions } from "../AppReducer";
import { MobileNavigation } from "./MobileNavigation";
import { featureToggles } from "../featureTogge.config";
import { MEDIA_PATH } from "../AppReducer";

const StyledNavigation = styled.nav`
  margin: 16px;
  display: flex;
  width: calc(100% - 80px);
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: ${colors.black};
  padding: 0 24px;
  position: fixed;
  z-index: 100;
  box-shadow: 0 0 16px rgb(25 25 25 / 40%);
  border-radius: 8px;
  transition: all 200ms ease-in-out;
  filter: drop-shadow(0 0 2px ${colors.teal});

  svg {
    fill: ${colors.white};
    transform: scale(0.5);
  }

  @media (min-width: ${breakpoints.TABLET}px) {
    padding: 0 48px;
    width: calc(100% - 128px);
  }

  @media (min-width: ${breakpoints.DESKTOP}px) {
    padding: 0 100px;
    width: calc(100% - 232px);
  }

  ${({ isScrolled }) =>
    isScrolled &&
    `
    margin: 0;
		border-radius: 0;
    transition: all 200ms ease-in-out;
    width: calc(100% - 48px);
    
    @media (min-width: ${breakpoints.TABLET}px) {
      width: calc(100% - 96px);
    }
    
    @media (min-width: ${breakpoints.DESKTOP}px) {
      padding: 0 100px;
      width: calc(100% - 200px);
    }
  `};
`;

const StyledList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
`;

const StyledListItem = styled.li`
  margin: 0 24px;
  cursor: pointer;
  color: ${colors.white};

  &:hover {
    color: ${colors.lightgrey};
  }
`;

const StyledLogo = styled.img`
  height: 40px;
  cursor: pointer;
`;

const StyledCallToAction = styled.a`
  color: ${colors.white};
  border-radius: 4px;
  background: ${colors.primaryGradient};
  padding: 12px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: filter 150ms ease-in-out;
  outline: none;
  text-decoration: none;

  &:hover {
    filter: brightness(1.15);
  }
`;

export const Navigation = () => {
  const { state, refs, dispatch } = useContext(AppContext);
  const [windowScrolled, setWindowScrolled] = useState(false);
  const { currentWidth } = useWindowSize();

  const scrollToSection = (index) => {
    const scrollOffset = index === 0 ? 72 + 48 : 144 + 48;
    window.scrollTo({
      top: Object.values(refs)[index].current.offsetTop - scrollOffset,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderNavigationElementsBasedOnViewPort = () => {
    if (currentWidth >= breakpoints.DESKTOP) {
      return (
        <StyledList>
          {state.navigation.navLinks.map((listItem, index) => {
            return (
              <StyledListItem
                key={index}
                onClick={() => scrollToSection(index)}
              >
                {listItem}
              </StyledListItem>
            );
          })}
          <StyledCallToAction href={state.navigation.callToAction.url} download>
            {state.navigation.callToAction.title}
          </StyledCallToAction>
        </StyledList>
      );
    } else {
      return (
        <>
          <IconMenu
            onClick={() =>
              dispatch({
                type: Actions.SHOW_MOBILE_NAVIGATION,
                payload: !state.navigation.showMobileNavigation,
              })
            }
          />
          {state.navigation.showMobileNavigation && (
            <MobileNavigation scrollToSection={scrollToSection} />
          )}
        </>
      );
    }
  };

  const getNavigationPointsBasedOnFeatureToggle = () => {
    if (!featureToggles?.contactForm) {
      const tempNavPoints = [...state?.navigation?.navLinks].filter(
        (navPoint) => navPoint !== "Kontakt"
      );
      dispatch({ type: Actions.ADAPT_NAV_LINKS, payload: tempNavPoints });
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setWindowScrolled(window.pageYOffset !== 0);
    });

    getNavigationPointsBasedOnFeatureToggle();
  }, []);

  return (
    <StyledNavigation isScrolled={windowScrolled}>
      <StyledLogo
        src={`${MEDIA_PATH}/images/koelle-it-logo.png`}
        onClick={() => scrollToTop()}
        alt="Kölle-IT"
      />
      {renderNavigationElementsBasedOnViewPort()}
    </StyledNavigation>
  );
};
