import styled from "styled-components";
import { colors } from "../stylingVariables";

const StyledButton = styled.button`
  color: ${colors.white};
  border-radius: 4px;
  background: ${colors.primaryGradient};
  padding: 12px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  outline: none;
  border: 0;
  font-size: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  :disabled {
    opacity: 0.4;
  }

  &:hover {
    filter: brightness(1.15);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
`;

export const Button = ({ children, onClick, type, disabled }) => {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};
