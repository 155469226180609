import { breakpoints } from "./constants";

export const colors = {
	black: '#000',
	lightBlack: '#0e0e0e',
	blackTransparent: "rgba(0,0,0,.6)",
	white: "rgb(255,255,255)",
	lightgrey: "rgb(200,200,200)",
	cyan: "#16B2C9",
	teal: "#1D8FA5",
	red: "#eb0000",
	primaryGradient: "linear-gradient(90deg,#16B2C9,#1D8FA5)",
};

export const sectionLayout = `
	width: calc(100% - 64px);
	padding: 80px 32px;
	color: ${colors.white};
	
	&:nth-child(odd) {
	  background-color: ${colors.lightBlack};
	  clip-path: polygon(0 0, 100% 48px, 100% calc(100% - 48px), 0 100%);
	}
	
	&:nth-child(even) {
	  background-color: ${colors.black};
	}
	
	&:nth-child(2) {
	  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 48px), 0% 100%);
	}

	@media(min-width: ${breakpoints.TABLET}) {
		width: calc(100% - 128px);
		padding: 80px 64px;
	}

	@media(min-width: ${breakpoints.DESKTOP}) {
		width: calc(100% - 96px);
		padding: 120px 48px;
	}
`;
