import styled from "styled-components";
import { createContext, useEffect, useRef, useReducer } from "react";
import { Company } from "./components/Company";
import { Contact } from "./components/Contact";
import { Jumbotron } from "./components/Jumbotron";
import { Navigation } from "./components/Navigation";
import { Partner } from "./components/Partner";
import { Products } from "./components/Products";
import { References } from "./components/References";
import { Modal } from "./components/Modal";
import { Dataprivacy } from "./components/Dataprivacy";
import { FooterNav } from "./components/FooterNav";
import { FOOTER_NAV_ITEM, initialState, reducer } from "./AppReducer";
import { Imprint } from './components/Imprint';
import { colors } from "./stylingVariables";
import { featureToggles } from "./featureTogge.config";

export const AppContext = createContext(null);

const StyledApp = styled.div`
  h1,h2,h3,h4,h5 {
    font-family: 'Montserrat', sans-serif;
  }
`;

const StyledHeader = styled.header`
  height: 72px;
  z-index: 100;
`;

const StyledMain = styled.main`
  position: absolute;
  top: -72px;
  background-color: ${colors.black};
  width: 100%;
  left: 0;
`

function App() {
  const productSection = useRef();
  const referencesSection = useRef();
  const companySection = useRef();
  const partnerSection = useRef();
  const contactSection = useRef();
  const [state, dispatch] = useReducer(reducer, initialState);

  const renderModalContent = () => {
    const { headline } = state.currentFooterContent;
    if (headline === FOOTER_NAV_ITEM.IMPRINT) {
      return <Imprint />;
    }
    if (headline === FOOTER_NAV_ITEM.DATA_PRIVACY) {
      return <Dataprivacy />;
    }
  };

  useEffect(() => {
    if (state.showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [state.showModal]);

  return (
    <StyledApp>
      <AppContext.Provider
        value={{
          state,
          dispatch,
          refs: {
            partnerSection,
            productSection,
            referencesSection,
            companySection,
            contactSection,
          },
        }}
      >
        <StyledHeader>
          <Navigation />
        </StyledHeader>
        <StyledMain>
          <Jumbotron />
          <Partner />
          <Products />
          <References />
          <Company />
          {featureToggles?.contactForm && <Contact />}
          <FooterNav />
        </StyledMain>
        {state.showModal &&
          <Modal headline={state.currentFooterContent.headline}>
            {renderModalContent()}
          </Modal>
        }
      </AppContext.Provider>
    </StyledApp>
  );
}

export default App;
