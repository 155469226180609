import styled from "styled-components";
import { LogoFacebook, LogoLinkedin, LogoGoogle } from 'react-ionicons'
import { AppContext } from "../App";
import {useContext} from 'react';
import { colors } from "../stylingVariables";

const ICON_SIZE = 30;

const StyledWrapper = styled.div`
  display: flex;
	justify-content: center;
	align-items: center;
`;
	
const StyledLogoContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 24px;
`;

const SocialIconBar = () => {
  const { state } = useContext(AppContext);
	const openLinkInNewTab = (link) => window.open(link, '_blank');
	
	return (
		<StyledWrapper>
			<StyledLogoContainer>
				<LogoFacebook cssClasses={'social-media-icon'} color={colors.cyan} height={`${ICON_SIZE}px`} width={`${ICON_SIZE}px`} onClick={() => openLinkInNewTab(state.socialMedia.facebook)}/>
				<LogoLinkedin cssClasses={'social-media-icon'} color={colors.cyan} height={`${ICON_SIZE}px`} width={`${ICON_SIZE}px`} onClick={() => openLinkInNewTab(state.socialMedia.linkedIn)}/>
				<LogoGoogle cssClasses={'social-media-icon'} color={colors.cyan} height={`${ICON_SIZE}px`} width={`${ICON_SIZE}px`} onClick={() => openLinkInNewTab(state.socialMedia.google)}/>
			</StyledLogoContainer>
		</StyledWrapper>
	)
}

export default SocialIconBar;