import styled from "styled-components";

const SyteledImprintContainer = styled.div``;

const StyledImprintSubHeadline = styled.h3`
  width: 100%;
`;

export const Imprint = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <SyteledImprintContainer>
      <div>
        <StyledImprintSubHeadline>Adresse</StyledImprintSubHeadline>
        Kölle IT-Systeme
        <br />
        Inh. Jörg Kölle
        <br />
        Sommerbergstrasse 99
        <br />
        73349 Wiesensteig
      </div>
      <div>
        Handy (0179) 9128835
        <br />
        Tel. (07335) 5266
        <br />
        Fax. (07335) 7792
      </div>
      <div>
        <StyledImprintSubHeadline>Webseiten</StyledImprintSubHeadline>
        www.koelle-it.de
        <br />
        www.koelleit.de <br />
        www.kölle-it.de <br />
        E-Mail: <a href="mailto:info@koelle-it.de">info(at)koelle-it.de</a>
      </div>
      <div>
        <StyledImprintSubHeadline>Gesellschaftssitz</StyledImprintSubHeadline>
        Wiesensteig
      </div>
      <div>
        <StyledImprintSubHeadline>Steuernummer</StyledImprintSubHeadline>
        62533/50737
      </div>
      <div>
        <StyledImprintSubHeadline>Rechtlicher Hinweis</StyledImprintSubHeadline>
        Kölle IT-Systeme bemüht sich, auf ihrer Website zutreffende Informationen und Daten zur Verfügung zu stellen.
        Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten
        Informationen und Daten ist jedoch ausgeschlossen. Dies gilt ebenfalls auch für alle anderen direkt oder
        indirekt angeführten Websites, auf die mittels eines Hyperlinks verwiesen wird. Kölle- IT Systeme ist für den
        Inhalt solcher Websites, die mit diesen Links erreicht werden, nicht verantwortlich.
      </div>
      <div>&copy; Copyright {getCurrentYear()} Kölle IT-Systeme</div>
    </SyteledImprintContainer>
  );
};
