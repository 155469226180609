import {useContext, useState} from "react";
import styled from "styled-components";
import {sectionLayout} from "../stylingVariables";
import {AppContext} from "../App";
import {Document, Page} from "react-pdf";
import {SectionHeadline} from "./SectionHeadline";
import {Button} from "./Button";

const StyledProducts = styled.section`
  ${sectionLayout};
`;

const StyledDocumentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledDocument = styled.div`
`;

const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  grid-gap: 16px;
`;

export const Products = () => {
  const { state, refs } = useContext(AppContext);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <StyledProducts ref={refs.productSection}>
      <SectionHeadline index={1} />
      <StyledDocumentContainer>
        <StyledDocument>
          <Document file={state.products.catalog.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={1.0}/>
          </Document>
          <StyledNavigation>
            <p>
              Seite {pageNumber || (numPages ? 1 : "--")} von {numPages || "--"}
            </p>
            <StyledButtonContainer>
              <Button onClick={previousPage} type={"button"} disabled={pageNumber <= 1}>Zurück</Button>
              <Button onClick={nextPage} type={"button"} disabled={pageNumber >= numPages}>Vor</Button>
            </StyledButtonContainer>
            <p>gültig bis {state.products.catalog.vaildUntil}</p>
          </StyledNavigation>
        </StyledDocument>
      </StyledDocumentContainer>
    </StyledProducts>
  );
};