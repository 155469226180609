import styled from "styled-components";
import { sectionLayout } from "../stylingVariables";
import { useContext } from "react";
import { AppContext } from "../App";
import { SectionHeadline } from "./SectionHeadline";
import { CompanyService } from "./CompanyService";
import { breakpoints } from "../constants";

const StyledCompany = styled.section`
  ${sectionLayout};
`;

const StyledClaim = styled.h3`
  text-align: center;
  line-height: 200%;
  font-weight: 500;
`;

const StyledServiceContainer = styled.div`
  margin: 56px 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (min-width: ${breakpoints.TABLET}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.DESKTOP}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Company = () => {
  const { refs, state } = useContext(AppContext);
  const { company } = state;
  return (
    <StyledCompany ref={refs.companySection}>
      <SectionHeadline index={3} />
      <StyledClaim>{company.claim}</StyledClaim>

      <StyledServiceContainer>
        {company.services.map((service, index) => {
          return <CompanyService data={service} key={index} />;
        })}
      </StyledServiceContainer>
    </StyledCompany>
  );
};
