import { useContext } from "react";
import styled from "styled-components";
import { colors } from "../stylingVariables";
import { createPortal } from "react-dom";
import { IconCross } from "../icons/IconCross";
import { AppContext } from "../App";
import { Actions } from "../AppReducer";
import {breakpoints} from "../constants";

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${colors.blackTransparent};
  z-index: 100;
`;

const StyledModal = styled.div`
  overflow-y: scroll;
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
  bottom: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  color: ${colors.black};
  
  @media (min-width: ${breakpoints.TABLET}px) {
		top: 32px;
    left: 32px;
    right: 32px;
    bottom: 32px;
	}
  
  @media (min-width: ${breakpoints.BIG_TABLET}px) {
		top: 48px;
    left: 48px;
    right: 48px;
    bottom: 48px;
	}
	
  @media (min-width: ${breakpoints.DESKTOP}px) {
		top: 48px;
    left: 112px;
    right: 112px;
    bottom: 48px;
	}
`;

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  text-align: center;
  padding: 24px 0;
  box-shadow: 0 0 4px rgb(25 25 25 / 40%);
  background-color: ${colors.white};
`;
const StyledHeadline = styled.h2`
  margin: 0;
`;
const StyledIcon = styled.span`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 28px;
  cursor: pointer;
  transform: scale(1) rotate(0);
  transition: transform 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
  svg {
    fill: ${colors.black};
    transition: fill 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  &:hover {
    transform: scale(1.3) rotate(180deg);
    svg {
      fill: ${colors.cyan};
      transition: fill 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    transition: transform 200ms cubic-bezier(0.65, 0.05, 0.36, 1);
  }
`;
const StyledContent = styled.div`
  padding: 48px;

  a {
    color: ${colors.cyan};
  }
`;

export const Modal = ({ headline, children }) => {
  const { dispatch } = useContext(AppContext);
  return createPortal(
    <StyledContainer>
      <StyledModal>
        <StyledHeader>
          <StyledHeadline>{headline}</StyledHeadline>
          <StyledIcon
            onClick={() =>
              dispatch({ type: Actions.SHOW_MODAL, payload: false })
            }
          >
            <IconCross />
          </StyledIcon>
        </StyledHeader>
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </StyledContainer>,
    document.body
  );
};
