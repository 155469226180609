import { Formik, Form, Field } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { AppContext } from "../App";
import { Button } from "./Button";
import { FormFieldInput } from "./FormFieldInput";
import { FormFieldTextArea } from "./FormFieldTextArea";
import styled from "styled-components";
import { breakpoints } from "../constants";
import { CheckmarkCircle } from "react-ionicons";

const ContactSchema = (contactForm) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, contactForm.name.errors.min)
      .max(50, contactForm.name.errors.max),
    message: Yup.string()
      .min(2, contactForm.message.errors.min)
      .max(50, contactForm.message.errors.max),
    email: Yup.string().email(contactForm.email.errors.email),
  });
  return validationSchema;
};

const StyledFormContainer = styled.div`
  @media (min-width: ${breakpoints.DESKTOP}px) {
    width: 50%;
  }
`;

const StyledSubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center:
`;

const StyledMessage = styled.p`
  color: white;
  margin: 0;
  padding: 0;
  margin-left: 4px;
`;

export const ContactForm = () => {
  const { state } = useContext(AppContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <StyledFormContainer>
      <Formik
        initialValues={{
          name: "",
          message: "",
          email: "",
          thiscantbereal: "",
        }}
        validationSchema={() => ContactSchema(state.contactForm)}
        onSubmit={async (values, { resetForm }) => {
          // same shape as initial values
          // const response = await fetch(
          //   process.env.REACT_APP_EMAIL_SERVER + "/send-mail",
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify(values, null, 2),
          //   }
          // );
          const response = await fetch(
            process.env.REACT_APP_EMAIL_SERVER + "/protected",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
              },
              body: JSON.stringify(values, null, 2)
            }
          );

          const result = await response.json();

          if (result.messageId) {
            setTimeout(() => setShowConfirmation(false), 3000);
            setShowConfirmation(true);
            resetForm();
          }
        }}
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <Form>
            <FormFieldInput
              name={"name"}
              placeholder={"Ihr Name"}
              hasError={errors.name && touched.name}
              errorFeedback={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              type={"text"}
              value={values.name}
            />
            <FormFieldTextArea
              name={"message"}
              placeholder={"Ihre Nachricht"}
              hasError={errors.message && touched.message}
              errorFeedback={errors.message}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            />
            <FormFieldInput
              name={"email"}
              placeholder={"Ihre E-Mail"}
              hasError={errors.email && touched.email}
              errorFeedback={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type={"email"}
              value={values.email}
            />
            <Field name="thiscantbereal" autoComplete="off" type="hidden" />
            <StyledSubmitWrapper>
              <Button type={"submit"}>{state.contactForm.submit.title}</Button>
              {showConfirmation && (
                <StyledMessageContainer>
                  <CheckmarkCircle color={"white"} width="24px" height="24px" />
                  <StyledMessage>
                    {state.contactForm.globalMessage}
                  </StyledMessage>
                </StyledMessageContainer>
              )}
            </StyledSubmitWrapper>
          </Form>
        )}
      </Formik>
    </StyledFormContainer>
  );
};
