import { useContext } from "react";
import { AppContext } from "../App";
import styled from "styled-components";
import { colors } from "../stylingVariables";
import { Actions, FOOTER_NAV_ITEM } from "../AppReducer";
import SocialIconBar from './SocialIconBar';

const StyledFooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 32px;
  background-color: ${colors.lightBlack};
  color: ${colors.white};
  width: calc(100% - 64px);
`;

const StyledFooterNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const StyledFooterNavItem = styled.a`
  margin-right: 30px;
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${colors.lightgrey};
  }
`;

export const FooterNav = () => {
  const { state, dispatch } = useContext(AppContext);

  const handleListItemClick = (listItem) => {
    if (listItem === FOOTER_NAV_ITEM.IMPRINT) {
      dispatch({
        type: Actions.SET_FOOTER_CONTENT,
        payload: {
          headline: state.imprint.headline,
        },
      });
    }
    if (listItem === FOOTER_NAV_ITEM.DATA_PRIVACY) {
      dispatch({
        type: Actions.SET_FOOTER_CONTENT,
        payload: {
          headline: state.dataprivacy.headline,
        },
      });
    }
    dispatch({ type: Actions.SHOW_MODAL, payload: !state.showModal });
  };

  return (
    <StyledFooterContainer>
      <SocialIconBar />
      <StyledFooterNav>
        {state.navigation.navFooterLinks.map((listItem, index) => (
          <StyledFooterNavItem
            key={index}
            onClick={() => handleListItemClick(listItem)}
          >
            {listItem}
          </StyledFooterNavItem>
        ))}
      </StyledFooterNav>
    </StyledFooterContainer>
  );
};
