import styled from "styled-components";
import { colors } from "../stylingVariables";

const StyledInput = styled.input`
  height: 32px;
  width: calc(100% - 16px);
  padding: 0 8px;
	margin-bottom: 24px;
  border: 1px solid ${colors.black};
  border-radius: 2px;
  ${({ hasError }) =>
    hasError &&
    `
    border-color: ${colors.red};
		margin-bottom: 0;
  `};
`;

const StyledErrorMessage = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 4px 0 24px 8px;
  color: ${colors.red};
`;

export const FormFieldInput = ({
  name,
  placeholder,
  hasError,
  errorFeedback,
  type,
  onChange,
  onBlur,
  value
}) => {
  return (
    <>
      <StyledInput
        type={type}
        name={name}
        placeholder={placeholder}
        hasError={hasError}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {hasError && <StyledErrorMessage>{errorFeedback}</StyledErrorMessage>}
    </>
  );
};
