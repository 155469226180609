import styled from "styled-components";
import { MEDIA_PATH } from "../AppReducer";
import { breakpoints } from "../constants";
import {colors, sectionLayout} from "../stylingVariables";

const StyledJumbotron = styled.section`
  ${sectionLayout};
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("${MEDIA_PATH}/images/joerg-koelle-tablet.jpg");

  @media (min-width: ${breakpoints.DESKTOP}px) {
    background-image: url("${MEDIA_PATH}/images/joerg-koelle.jpg");
    height: 70vh;
  }
`;

const StyledJumbotronHighlight = styled.div`
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  color: ${colors.white};
  width: calc(100% - 64px);
  
  @media (min-width: ${breakpoints.TABLET}px) {
    width: 70%;
    left: 48px;
  }

  @media (min-width: ${breakpoints.BIG_TABLET}px) {
    width: 50%;
  }
  
  @media (min-width: ${breakpoints.DESKTOP}px) {
    left: 100px;
  }
`;

const StyledJumbotronHeadline = styled.h1`
  margin: 0 0 24px;
  font-family: 'Montserrat';
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.TABLET}px) {
    font-size: 56px;
  }

  @media (min-width: ${breakpoints.DESKTOP}px) {
    font-size: 72px;
  }
`;

const StyledJumbotronDescription = styled.p`
  margin-bottom: 56px;
  
  @media (min-width: ${breakpoints.TABLET}px) {
    width: 70%;
  }
  
  @media (min-width: ${breakpoints.BIG_TABLET}px) {
    width: 70%;
  }
`;

export const Jumbotron = () => {
  return (
    <StyledJumbotron>
      <StyledJumbotronHighlight>
        <StyledJumbotronHeadline>Grüß Gott...</StyledJumbotronHeadline>
        <StyledJumbotronDescription>
          <b>Computer</b> sind in Fällen nur dazu da, um Probleme zu lösen, die ohne <b>Computer</b> nicht entstanden wären...
          Deswegen helfe ich Ihnen gerne die Probleme der heutigen IT- Welt zu lösen, egal wie groß oder klein diese sind.
        </StyledJumbotronDescription>
      </StyledJumbotronHighlight>
    </StyledJumbotron>
  );
};
