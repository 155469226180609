import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { breakpoints } from "../constants";
import { sectionLayout } from "../stylingVariables";

const StyledPartner = styled.section`
  ${sectionLayout};
  
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-gap: 64px;

  @media (min-width: ${breakpoints.TABLET}px) {
    grid-template-columns: 1fr 1fr;
  };

  @media (min-width: ${breakpoints.DESKTOP}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  };
`;

const StyledImage = styled.img`
  height: 40px;
`;

export const Partner = () => {
  const { refs, state } = useContext(AppContext);
  return (
    <StyledPartner ref={refs.partnerSection}>
      {state.partner.map((partner, index) => {
        return <StyledImage src={partner.imageUrl} key={index} alt={`partner image ${index}`}/>;
      })}
    </StyledPartner>
  );
};
