import styled from "styled-components";

const SyteledImprintContainer = styled.div``;

const StyledImprintSubHeadline = styled.h3`
  width: 100%;
`;

export const Dataprivacy = () => {
  return (
    <SyteledImprintContainer>
      <div>
        Allgemeiner Hinweis und Pflichtinformationen
        <br />
        Benennung der verantwortlichen Stelle
        <br />
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        <br />
        <br />
        Kölle IT-Systeme
        <br />
        Jörg Kölle
        <br />
        Sommerbergstrasse 99
        <br />
        73349 Wiesensteig <br />
        <br />
        <br />
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        <br />
        <br />
      </div>
      <div>
        <StyledImprintSubHeadline>Widerruf Ihrer Einwilligung zur Datenverarbeitung</StyledImprintSubHeadline>
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer
        bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per
        E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </div>
      <div>
        <StyledImprintSubHeadline>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</StyledImprintSubHeadline>
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
        zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende
        Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
        <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </div>
      <div>
        <StyledImprintSubHeadline>Recht auf Datenübertragbarkeit</StyledImprintSubHeadline>
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
        automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
        maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.
      </div>
      <div>
        <StyledImprintSubHeadline>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</StyledImprintSubHeadline>
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über
        Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und
        auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum
        aufgeführten Kontaktmöglichkeiten an uns wenden.
      </div>
      <div>
        <StyledImprintSubHeadline>SSL- bzw. TLS-Verschlüsselung</StyledImprintSubHeadline>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
        senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website
        übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“
        Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
      </div>
      <div>
        <StyledImprintSubHeadline>Kontaktformular</StyledImprintSubHeadline>
        Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage
        bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre
        Einwilligung nicht statt.
        <br />
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
        möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        <br />
        Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
        Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende
        gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
      </div>
    </SyteledImprintContainer>
  );
};
